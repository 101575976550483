body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.statistics-container {
  padding: 16px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.statistics-no-content {
  font-size: 18px;
  padding: 15px;
}

.statistics-header {
  color: #282c34;
  padding-bottom: 4px;
  margin-bottom: 15px;
  margin-top: 0;
  font-size: 18px;
}
.statistics-header.margin-top {
  margin-top: 40px;
}

.statistics-title {
  color: #282c34;
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 4px;
}

.statistics-installation-count {
  padding-bottom: 12px;
}
.statistics-install-item {
  padding-left: 12px;
}
.statistics-city-count {
  padding-bottom: 6px;
}

.statistics-datepicker-block {
  color: rgba(0, 0, 0, 0.87);
  cursor: text;
  display: inline-flex;
  position: relative;
  font-size: 1rem;
  box-sizing: border-box;
  align-items: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;

  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  margin-bottom: 15px;
}

.statistics-datepicker {
  padding: 20px 0 6px 0;
  outline: none;
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;

  height: 1.1876em;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
}

.statistics-datepicker-block .react-datepicker-wrapper,
.statistics-datepicker-block select {
  padding-left: 12px;
  width: 210px;
  transition: background-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}

.statistics-datepicker-block select {
  padding-left: 8px;
  padding-bottom: 6.5px;
}
.statistics-datepicker-block {
  margin-left: 15px;
}
.statistics-datepicker-block:nth-child(1) {
  margin-left: 0px;
}
.statistics-datepicker-label {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 12px;
  color: rgba(0, 0, 0, 0.54);
}

.recharts-wrapper {
  margin-left: -40px;
}

