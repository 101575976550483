td{
    overflow-x: hidden;
}

.p-filter-column input {
    width:100%
}
.p-dropdown{
    width:100%
}
